const jwt = require('jsonwebtoken');

// 获取cookie
const getCookie = (cname) => {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let c of ca) {
        while (c.charAt(0) === ' ') { c = c.substring(1); }
        if (c.indexOf(name) !== -1) { return c.substring(name.length, c.length); }
    }
    return '';
};

// 获取一级域名
const getDomain = () => {
    let host = location.hostname;
    const ip = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    if (ip.test(host) === true || host === 'localhost') { return host; }
    const regex = /([^]*).*/;
    const match = host.match(regex);
    if (typeof match !== 'undefined' && null !== match) { host = match[1]; }
    if (typeof host !== 'undefined' && null !== host) {
        const strAry = host.split('.');
        if (strAry.length > 1) {
            host = strAry[strAry.length - 2] + '.' + strAry[strAry.length - 1];
        }
    }
    return '.' + host;
};

// cookie设置
const setCookie = (cname, value, expiredays = null) => {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie = cname + '=' + escape(value) + ((expiredays == null) ? '' : ';expires=' + exdate.toUTCString()) + ';domain=' + window.location.hostname + ';path=/;';
};

/**
 * 刷新权限
 */
const refreshToken = () => {
    const refreshToken = getCookie('refreshToken');
    const token = getCookie('token');
    return new Promise((resolve) => {
        if (refreshToken) {
            jQuery.ajax({
                url:"/api/auth/refreshToken",
                type: 'post',
                dataType: "json",
                headers: {
                    'Authorization': `Bearer ` + token,
                },
                data: {
                    refreshToken: refreshToken,
                },
                success: function(res) {
                    setCookie('token', res.token);
                    setCookie('refreshToken', res.refreshToken);
                    resolve(res);
                }});
        } else {
            resolve('');
        }
    });
};

/**
 * 解析token,获取身份信息
 */
const getUserId = () => {
    return jwt.decode(getCookie('token'));
};

window.getCookie = getCookie;
window.getDomain = getDomain;
window.setCookie = setCookie;
window.refreshToken = refreshToken;
window.getUserId = getUserId;
